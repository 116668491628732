import { InvGoodsIssueDtlModel } from "../inventory/inv-goods-issue-dtl.model";

export class DashboardViewModel {
  TotalFinalized: number;
  SpecDistriAnalyzeres: SpecDistributionAnalyzer[];
  DepartmentDistributions: DeptDistribution[];
  SpeciesDistributions: SpeciesDistribution[];
  DailyFinalizedDistributions: DailyFinalizedDistribution[];
  AvgDailyFinalizedDistributions: DailyFinalizedDistribution[];
  HourlyFinalizedDistributions: HourlyFinalizedDistribution[];
  AvgHourlyFinalizedDistributions: HourlyFinalizedDistribution[];
  AnalyzerDistributions: AnalyzerDistribution[];
  AvgAnalyzerDistributions: AnalyzerDistribution[];
  TATAnalyzerDistributions: TATAnalyzerDistribution[];
  ExamOccurDistributions: ExamOccurDistribution[];
  SlideProfitLoss: SlideProfitLoss[];
  AvgSlideProfitLoss: SlideProfitLoss[];
  AnalyzerProfitLoss: AnalyzerProfitLoss[];
  AvgAnalyzerProfitLoss: AnalyzerProfitLoss[];
  InvGoodsIssueDtlModel: InvGoodsIssueDtlModel[];
  AvgInvGoodsIssueDtlModel: InvGoodsIssueDtlModel[];
  HourlyAnalyzerFinalizedDistribution: HourlyAnalyzerFinalizedDistribution[];
  DailyAnalyzerFinalizedDistribution: DailyAnalyzerFinalizedDistribution[];
  SiteWiseFinalizedStudy: SiteWiseFinalizedStudy[];
  AvgSiteWiseFinalizedStudy: SiteWiseFinalizedStudy[];
}

export class SiteWiseFinalizedStudy {
  SiteName: string;
  TotalStudyCount: number;
}

export class SpecDistributionAnalyzer {
  ItemName: string;
  SpeciesName: string;
  SpeciesDisbursCount: number;
}

export class DeptDistribution {
  /**
   *
   */
  constructor() {
    this.DepartmentName = "";
    this.TotalStudyCount = 0;
  }
  DepartmentName: string;
  TotalStudyCount: number;
}

export class SpeciesDistribution {
  SpeciesName: string;
  TotalStudyCount: number;
}

export class DailyFinalizedDistribution {
  DayName: string;
  DayNo: number;
  TotalStudyCount: number;
}

export class DailyAnalyzerFinalizedDistribution {
  DayName: string;
  DayNo: number;
  TotalStudyCount: number;  
  AnalyzerName: string;
}

export class HourlyFinalizedDistribution {
  HourlyNo: number;
  TotalStudyCount: number;
}

export class HourlyAnalyzerFinalizedDistribution {
  HourlyNo: number;
  TotalStudyCount: number;
  AnalyzerName: string;
}

export class AnalyzerDistribution {
  ItemName: string;
  DisbursCount: number;
}

export class TATAnalyzerDistribution {
  ItemName: string;
  ExamDisbursCount: number;
  AvgMin: number;
}

export class ExamOccurDistribution {
  ExamName: string;
  ExamDisbursCount: number;
}

export class SlideProfitLoss {
  ItemName: string;
  ItemId: number;
  PLAmount: number;
}

export class NextStockGoingOutSlide {
  Days: number;
  Item: string;
  CurrentStock: number;
}

export class AnalyzerProfitLoss {
  AnalyzerName: string;
  AnalyzerId: number;
  PLAmount: number;
  IsRental?: boolean;
}

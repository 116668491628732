import { Injectable } from "@angular/core";
import {
  GeneralResModel,
  TwoFABackupCodeModel,
  TwoFaVerifyModel,
} from "src/app/_models";
import { Gblenv } from "src/app/_models/orginfo/gblenv.model";
import {
  PwdValidatorParam,
  PwdValidatorRes,
  User,
} from "src/app/_models/user-models/user";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiPATH = "Auth/";
  private apiUserPATH = "User/";
  constructor(private api: ApiService) {}

  getUser2faAuthVerifyingTOTP(request: User) {
    return this.api.post<boolean>(
      `${this.apiPATH}GetUser2faAuthVerifyingTOTP`,
      request
    );
  }

  user2faAuthBackupCodesVerify(request: User) {
    return this.api.post<GeneralResModel<User>>(
      `${this.apiPATH}User2faAuthBackupCodesVerify`,
      request
    );
  }

  user2faAuth(request: User) {
    return this.api.post<boolean>(`${this.apiPATH}User2faAuth`, request);
  }

  user2faVerifyingTOTPBySecretkey(request: TwoFaVerifyModel) {
    return this.api.post<boolean>(
      `${this.apiPATH}User2faVerifyingTOTPBySecretkey`,
      request
    );
  }

  getUser2faAuthTotpSecretKey(userId: number) {
    return this.api.get<User>(
      `${this.apiPATH}GetUser2faAuthTotpSecretKey/${userId}`
    );
  }

  get2faAuthBackupCodes(user: User) {
    return this.api.post<TwoFABackupCodeModel[]>(
      `${this.apiPATH}Get2faAuthBackupCodes`,
      user
    );
  }

  user2faAuthDisable(empId: number) {
    return this.api.post<GeneralResModel<string>>(
      `${this.apiPATH}User2faAuthDisable`,
      empId
    );
  }

  setPreferredLandingPage(request: User) {
    return this.api.post<boolean>(
      `${this.apiUserPATH}setPreferredLandingPage`,
      request
    );
  }

  login(model: any) {
    var userModel = {
      userEmail: model.Useremail,
      password: model.Password,
      isCloud: model.IsCloud,
    };
    return this.api.post<any>(`${this.apiPATH}login`, userModel);
  }

  logout(userId: number) {
    return this.api.get<any>(`${this.apiPATH}logout/${userId}`);
  }

  getSharebleLinkById(linkId: string) {
    return this.api.get<string>(`${this.apiPATH}getSharebleLinkById/${linkId}`);
  }

  LoginWithLine(code: string, state: string) {
    return this.api.get<any>(`${this.apiPATH}OAuth/${code}/${state}`);
  }
  getAccessToken(): string {
    return localStorage.getItem("token");
  }

  ValidatePassword(request: PwdValidatorParam) {
    return this.api.post<GeneralResModel<PwdValidatorRes>>(
      `${this.apiPATH}ValidatePassword`,
      request
    );
  }
  GetPassPolicy(orgId: number) {
    return this.api.get<GeneralResModel<Gblenv>>(
      `${this.apiPATH}GetPassPolicy/${orgId}`
    );
  }
}

import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, Subject, switchMap } from "rxjs";
import { Gblenv } from "src/app/_models/orginfo/gblenv.model";
import { AuthService } from "src/app/_services/auth/auth.service";
import { ResetPassword } from "../../../../_models/user-models/reset-password";
import {
  PwdValidatorParam,
  PwdValidatorRes,
  User,
} from "../../../../_models/user-models/user";
import { AlertifyService } from "../../../../_services/common/alertify.service";
import { UserService } from "../../../../_services/user/user.service";

@Component({
  selector: "app-radiologist-reset-password",
  templateUrl: "./radiologist-reset-password.component.html",
  styleUrls: ["./radiologist-reset-password.component.scss"],
})
export class RadiologistResetPasswordComponent implements OnInit {
  selectedEmailOrPhoneNumber: any;
  selectedOtp: any;
  emailExists: unknown;

  //Pwd Policy
  pwdPolicyOrg: Gblenv;
  isPolicyRestored: boolean = false;
  isPasswordValid: boolean;
  validationMessage: string[];
  validationMessageForTT: string;
  pwdValidatorRes: PwdValidatorRes;
  pwdValidatorParam: PwdValidatorParam = new PwdValidatorParam();
  isPasswordMasked: boolean = true;

  constructor(
    private HttpClient: HttpClient,
    private userService: UserService,
    private alertify: AlertifyService,
    private _authService: AuthService,
    private router: Router
  ) {}

  isSendOtp: boolean = false;
  isVerifySuccessfull: boolean = false;
  isTimeOut: boolean = false;
  selectedConfirmPassword: string;
  selectedPassword: string;
  resetForm: FormGroup;
  resetpass: ResetPassword;
  user: User;
  isLoaderVisible: boolean = false;
  searchEmailTextSubject$ = new Subject<{ email: string; Id: number }>();
  selectedPhoneCode: any;

  ngOnInit() {
    let country = {
      Andorra: "Andorra",
      Dubai: "United Arab Emirates",
      Kabul: "Afghanistan",
      Tirane: "Albania",
      Yerevan: "Armenia",
      Casey: "Antarctica",
      Davis: "Antarctica",
      Mawson: "Antarctica",
      Palmer: "Antarctica",
      Rothera: "Antarctica",
      Troll: "Antarctica",
      Vostok: "Antarctica",
      Buenos_Aires: "Argentina",
      Cordoba: "Argentina",
      Salta: "Argentina",
      Jujuy: "Argentina",
      Tucuman: "Argentina",
      Catamarca: "Argentina",
      La_Rioja: "Argentina",
      San_Juan: "Argentina",
      Mendoza: "Argentina",
      San_Luis: "Argentina",
      Rio_Gallegos: "Argentina",
      Ushuaia: "Argentina",
      Pago_Pago: "Samoa (American)",
      Vienna: "Austria",
      Lord_Howe: "Australia",
      Macquarie: "Australia",
      Hobart: "Australia",
      Melbourne: "Australia",
      Sydney: "Australia",
      Broken_Hill: "Australia",
      Brisbane: "Australia",
      Lindeman: "Australia",
      Adelaide: "Australia",
      Darwin: "Australia",
      Perth: "Australia",
      Eucla: "Australia",
      Baku: "Azerbaijan",
      Barbados: "Barbados",
      Dhaka: "Bangladesh",
      Brussels: "Belgium",
      Sofia: "Bulgaria",
      Bermuda: "Bermuda",
      Brunei: "Brunei",
      La_Paz: "Bolivia",
      Noronha: "Brazil",
      Belem: "Brazil",
      Fortaleza: "Brazil",
      Recife: "Brazil",
      Araguaina: "Brazil",
      Maceio: "Brazil",
      Bahia: "Brazil",
      Sao_Paulo: "Brazil",
      Campo_Grande: "Brazil",
      Cuiaba: "Brazil",
      Santarem: "Brazil",
      Porto_Velho: "Brazil",
      Boa_Vista: "Brazil",
      Manaus: "Brazil",
      Eirunepe: "Brazil",
      Rio_Branco: "Brazil",
      Thimphu: "Bhutan",
      Minsk: "Belarus",
      Belize: "Belize",
      St_Johns: "Canada",
      Halifax: "Canada",
      Glace_Bay: "Canada",
      Moncton: "Canada",
      Goose_Bay: "Canada",
      Toronto: "Canada",
      Nipigon: "Canada",
      Thunder_Bay: "Canada",
      Iqaluit: "Canada",
      Pangnirtung: "Canada",
      Winnipeg: "Canada",
      Rainy_River: "Canada",
      Resolute: "Canada",
      Rankin_Inlet: "Canada",
      Regina: "Canada",
      Swift_Current: "Canada",
      Edmonton: "Canada",
      Cambridge_Bay: "Canada",
      Yellowknife: "Canada",
      Inuvik: "Canada",
      Dawson_Creek: "Canada",
      Fort_Nelson: "Canada",
      Whitehorse: "Canada",
      Dawson: "Canada",
      Vancouver: "Canada",
      Cocos: "Cocos (Keeling) Islands",
      Zurich: "Switzerland",
      Abidjan: "Côte d'Ivoire",
      Rarotonga: "Cook Islands",
      Santiago: "Chile",
      Punta_Arenas: "Chile",
      Easter: "Chile",
      Shanghai: "China",
      Urumqi: "China",
      Bogota: "Colombia",
      Costa_Rica: "Costa Rica",
      Havana: "Cuba",
      Cape_Verde: "Cape Verde",
      Christmas: "Christmas Island",
      Nicosia: "Cyprus",
      Famagusta: "Cyprus",
      Prague: "Czech Republic",
      Berlin: "Germany",
      Copenhagen: "Denmark",
      Santo_Domingo: "Dominican Republic",
      Algiers: "Algeria",
      Guayaquil: "Ecuador",
      Galapagos: "Ecuador",
      Tallinn: "Estonia",
      Cairo: "Egypt",
      El_Aaiun: "Western Sahara",
      Madrid: "Spain",
      Ceuta: "Spain",
      Canary: "Spain",
      Helsinki: "Finland",
      Fiji: "Fiji",
      Stanley: "Falkland Islands",
      Chuuk: "Micronesia",
      Pohnpei: "Micronesia",
      Kosrae: "Micronesia",
      Faroe: "Faroe Islands",
      Paris: "France",
      London: "Britain (UK)",
      Tbilisi: "Georgia",
      Cayenne: "French Guiana",
      Gibraltar: "Gibraltar",
      Nuuk: "Greenland",
      Danmarkshavn: "Greenland",
      Scoresbysund: "Greenland",
      Thule: "Greenland",
      Athens: "Greece",
      South_Georgia: "South Georgia & the South Sandwich Islands",
      Guatemala: "Guatemala",
      Guam: "Guam",
      Bissau: "Guinea-Bissau",
      Guyana: "Guyana",
      Hong_Kong: "Hong Kong",
      Tegucigalpa: "Honduras",
      "Port-au-Prince": "Haiti",
      Budapest: "Hungary",
      Jakarta: "Indonesia",
      Pontianak: "Indonesia",
      Makassar: "Indonesia",
      Jayapura: "Indonesia",
      Dublin: "Ireland",
      Jerusalem: "Israel",
      Kolkata: "India",
      Chagos: "British Indian Ocean Territory",
      Baghdad: "Iraq",
      Tehran: "Iran",
      Reykjavik: "Iceland",
      Rome: "Italy",
      Jamaica: "Jamaica",
      Amman: "Jordan",
      Tokyo: "Japan",
      Nairobi: "Kenya",
      Bishkek: "Kyrgyzstan",
      Tarawa: "Kiribati",
      Kanton: "Kiribati",
      Kiritimati: "Kiribati",
      Pyongyang: "Korea (North)",
      Seoul: "Korea (South)",
      Almaty: "Kazakhstan",
      Qyzylorda: "Kazakhstan",
      Qostanay: "Kazakhstan",
      Aqtobe: "Kazakhstan",
      Aqtau: "Kazakhstan",
      Atyrau: "Kazakhstan",
      Oral: "Kazakhstan",
      Beirut: "Lebanon",
      Colombo: "Sri Lanka",
      Monrovia: "Liberia",
      Vilnius: "Lithuania",
      Luxembourg: "Luxembourg",
      Riga: "Latvia",
      Tripoli: "Libya",
      Casablanca: "Morocco",
      Monaco: "Monaco",
      Chisinau: "Moldova",
      Majuro: "Marshall Islands",
      Kwajalein: "Marshall Islands",
      Yangon: "Myanmar (Burma)",
      Ulaanbaatar: "Mongolia",
      Hovd: "Mongolia",
      Choibalsan: "Mongolia",
      Macau: "Macau",
      Martinique: "Martinique",
      Malta: "Malta",
      Mauritius: "Mauritius",
      Maldives: "Maldives",
      Mexico_City: "Mexico",
      Cancun: "Mexico",
      Merida: "Mexico",
      Monterrey: "Mexico",
      Matamoros: "Mexico",
      Mazatlan: "Mexico",
      Chihuahua: "Mexico",
      Ojinaga: "Mexico",
      Hermosillo: "Mexico",
      Tijuana: "Mexico",
      Bahia_Banderas: "Mexico",
      Kuala_Lumpur: "Malaysia",
      Kuching: "Malaysia",
      Maputo: "Mozambique",
      Windhoek: "Namibia",
      Noumea: "New Caledonia",
      Norfolk: "Norfolk Island",
      Lagos: "Nigeria",
      Managua: "Nicaragua",
      Amsterdam: "Netherlands",
      Oslo: "Norway",
      Kathmandu: "Nepal",
      Nauru: "Nauru",
      Niue: "Niue",
      Auckland: "New Zealand",
      Chatham: "New Zealand",
      Panama: "Panama",
      Lima: "Peru",
      Tahiti: "French Polynesia",
      Marquesas: "French Polynesia",
      Gambier: "French Polynesia",
      Port_Moresby: "Papua New Guinea",
      Bougainville: "Papua New Guinea",
      Manila: "Philippines",
      Karachi: "Pakistan",
      Warsaw: "Poland",
      Miquelon: "St Pierre & Miquelon",
      Pitcairn: "Pitcairn",
      Puerto_Rico: "Puerto Rico",
      Gaza: "Palestine",
      Hebron: "Palestine",
      Lisbon: "Portugal",
      Madeira: "Portugal",
      Azores: "Portugal",
      Palau: "Palau",
      Asuncion: "Paraguay",
      Qatar: "Qatar",
      Reunion: "Réunion",
      Bucharest: "Romania",
      Belgrade: "Serbia",
      Kaliningrad: "Russia",
      Moscow: "Russia",
      Simferopol: "Russia",
      Kirov: "Russia",
      Volgograd: "Russia",
      Astrakhan: "Russia",
      Saratov: "Russia",
      Ulyanovsk: "Russia",
      Samara: "Russia",
      Yekaterinburg: "Russia",
      Omsk: "Russia",
      Novosibirsk: "Russia",
      Barnaul: "Russia",
      Tomsk: "Russia",
      Novokuznetsk: "Russia",
      Krasnoyarsk: "Russia",
      Irkutsk: "Russia",
      Chita: "Russia",
      Yakutsk: "Russia",
      Khandyga: "Russia",
      Vladivostok: "Russia",
      "Ust-Nera": "Russia",
      Magadan: "Russia",
      Sakhalin: "Russia",
      Srednekolymsk: "Russia",
      Kamchatka: "Russia",
      Anadyr: "Russia",
      Riyadh: "Saudi Arabia",
      Guadalcanal: "Solomon Islands",
      Mahe: "Seychelles",
      Khartoum: "Sudan",
      Stockholm: "Sweden",
      Singapore: "Singapore",
      Paramaribo: "Suriname",
      Juba: "South Sudan",
      Sao_Tome: "Sao Tome & Principe",
      El_Salvador: "El Salvador",
      Damascus: "Syria",
      Grand_Turk: "Turks & Caicos Is",
      Ndjamena: "Chad",
      Kerguelen: "French Southern & Antarctic Lands",
      Bangkok: "Thailand",
      Dushanbe: "Tajikistan",
      Fakaofo: "Tokelau",
      Dili: "East Timor",
      Ashgabat: "Turkmenistan",
      Tunis: "Tunisia",
      Tongatapu: "Tonga",
      Istanbul: "Turkey",
      Funafuti: "Tuvalu",
      Taipei: "Taiwan",
      Kiev: "Ukraine",
      Uzhgorod: "Ukraine",
      Zaporozhye: "Ukraine",
      Wake: "US minor outlying islands",
      New_York: "United States",
      Detroit: "United States",
      Louisville: "United States",
      Monticello: "United States",
      Indianapolis: "United States",
      Vincennes: "United States",
      Winamac: "United States",
      Marengo: "United States",
      Petersburg: "United States",
      Vevay: "United States",
      Chicago: "United States",
      Tell_City: "United States",
      Knox: "United States",
      Menominee: "United States",
      Center: "United States",
      New_Salem: "United States",
      Beulah: "United States",
      Denver: "United States",
      Boise: "United States",
      Phoenix: "United States",
      Los_Angeles: "United States",
      Anchorage: "United States",
      Juneau: "United States",
      Sitka: "United States",
      Metlakatla: "United States",
      Yakutat: "United States",
      Nome: "United States",
      Adak: "United States",
      Honolulu: "United States",
      Montevideo: "Uruguay",
      Samarkand: "Uzbekistan",
      Tashkent: "Uzbekistan",
      Caracas: "Venezuela",
      Ho_Chi_Minh: "Vietnam",
      Efate: "Vanuatu",
      Wallis: "Wallis & Futuna",
      Apia: "Samoa (western)",
      Johannesburg: "South Africa",
      Antigua: "Antigua & Barbuda",
      Anguilla: "Anguilla",
      Luanda: "Angola",
      McMurdo: "Antarctica",
      DumontDUrville: "Antarctica",
      Syowa: "Antarctica",
      Aruba: "Aruba",
      Mariehamn: "Åland Islands",
      Sarajevo: "Bosnia & Herzegovina",
      Ouagadougou: "Burkina Faso",
      Bahrain: "Bahrain",
      Bujumbura: "Burundi",
      "Porto-Novo": "Benin",
      St_Barthelemy: "St Barthelemy",
      Kralendijk: "Caribbean NL",
      Nassau: "Bahamas",
      Gaborone: "Botswana",
      "Blanc-Sablon": "Canada",
      Atikokan: "Canada",
      Creston: "Canada",
      Kinshasa: "Congo (Dem. Rep.)",
      Lubumbashi: "Congo (Dem. Rep.)",
      Bangui: "Central African Rep.",
      Brazzaville: "Congo (Rep.)",
      Douala: "Cameroon",
      Curacao: "Curaçao",
      Busingen: "Germany",
      Djibouti: "Djibouti",
      Dominica: "Dominica",
      Asmara: "Eritrea",
      Addis_Ababa: "Ethiopia",
      Libreville: "Gabon",
      Grenada: "Grenada",
      Guernsey: "Guernsey",
      Accra: "Ghana",
      Banjul: "Gambia",
      Conakry: "Guinea",
      Guadeloupe: "Guadeloupe",
      Malabo: "Equatorial Guinea",
      Zagreb: "Croatia",
      Isle_of_Man: "Isle of Man",
      Jersey: "Jersey",
      Phnom_Penh: "Cambodia",
      Comoro: "Comoros",
      St_Kitts: "St Kitts & Nevis",
      Kuwait: "Kuwait",
      Cayman: "Cayman Islands",
      Vientiane: "Laos",
      St_Lucia: "St Lucia",
      Vaduz: "Liechtenstein",
      Maseru: "Lesotho",
      Podgorica: "Montenegro",
      Marigot: "St Martin (French)",
      Antananarivo: "Madagascar",
      Skopje: "North Macedonia",
      Bamako: "Mali",
      Saipan: "Northern Mariana Islands",
      Nouakchott: "Mauritania",
      Montserrat: "Montserrat",
      Blantyre: "Malawi",
      Niamey: "Niger",
      Muscat: "Oman",
      Kigali: "Rwanda",
      St_Helena: "St Helena",
      Ljubljana: "Slovenia",
      Longyearbyen: "Svalbard & Jan Mayen",
      Bratislava: "Slovakia",
      Freetown: "Sierra Leone",
      San_Marino: "San Marino",
      Dakar: "Senegal",
      Mogadishu: "Somalia",
      Lower_Princes: "St Maarten (Dutch)",
      Mbabane: "Eswatini (Swaziland)",
      Lome: "Togo",
      Port_of_Spain: "Trinidad & Tobago",
      Dar_es_Salaam: "Tanzania",
      Kampala: "Uganda",
      Midway: "US minor outlying islands",
      Vatican: "Vatican City",
      St_Vincent: "St Vincent",
      Tortola: "Virgin Islands (UK)",
      St_Thomas: "Virgin Islands (US)",
      Aden: "Yemen",
      Mayotte: "Mayotte",
      Lusaka: "Zambia",
      Harare: "Zimbabwe",
    };

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timezone != null) {
      let cityName = timezone.split("/")[1];
      // alert(cityName);

      if (country[cityName]) {
        // alert(country[cityName]);
      }
    }

    this.resetForm = new FormGroup(
      {
        EmailOrPhone: new FormControl("", Validators.required),
        Password: new FormControl("", Validators.required),
        ConfirmPassword: new FormControl("", Validators.required),
      },
      { validators: this.passwordMatch }
    );
  }

  passwordMatch(g: FormGroup) {
    return g.get("Password").value === g.get("ConfirmPassword").value
      ? null
      : { mismatch: true };
  }

  onOtpChange(event) {
    this.selectedOtp = event;
  }

  sentVerificationCode() {
    this.isLoaderVisible = true;
    this.resetpass = new ResetPassword();
    this.resetpass.Email = this.selectedEmailOrPhoneNumber;
    this.userService.requestForResetPasswordOTP(this.resetpass).subscribe(
      (x) => {
        if (x) {
          this.resetpass.ResetPasswordId = x;
          this.isSendOtp = true;

          this.isLoaderVisible = false;
        } else {
          this.isLoaderVisible = false;
          this.alertify.error("Failed to send OTP");
        }
      },
      (error) => {
        this.isLoaderVisible = false;
      }
    );
    // this.isSendOtp=true;
  }

  VerifyOtp() {
    this.resetpass.EmailOtp = this.selectedOtp;

    this.userService.VerifyOTP(this.resetpass).subscribe((x) => {
      if (x) {
        this.user = x;
        this.isVerifySuccessfull = true;
      }
    });
  }

  UpdatePassword() {
    if (this.isPasswordValid == false) {
      this.alertify.error("Password Invalid");
      return;
    }

    this.user.Pwd = this.selectedConfirmPassword;

    this.userService.changeRadiologistPassword(this.user).subscribe((x) => {
      if (x) {
        this.alertify.success("Password has been changed successfully");

        this.router.navigate(["/login"]);
      } else {
        this.alertify.success("Failed to reset password");
      }
    });
  }

  checkEmailOrPhoneNumber(event) {
    this.emailCheck();
    this.searchEmailTextSubject$.next({
      email: this.resetForm.controls["EmailOrPhone"].value,
      Id: 0,
    });
  }

  emailCheck() {
    this.searchEmailTextSubject$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchText) =>
          this.userService.emailAlreayExists(searchText.email)
        )
      )
      .subscribe((status) => {
        this.emailExists = status;
        // console.log('email exists ----',this.emailExists);
      });
  }

  isEmailValid() {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(this.selectedEmailOrPhoneNumber).toLowerCase());
  }
  validatePassword(event) {
    const password = (event.target as HTMLInputElement).value.trim();
    this.pwdValidatorParam.Password = password;

    this.pwdValidatorParam.OrgId = 0;

    const validatePasswordResult = (policy) => ({
      minLength: password.length >= policy.PwdLength,
      requireUppercase: /[A-Z]/.test(password),
      requireLowercase: /[a-z]/.test(password),
      requireNumber: /\d/.test(password),
      requireSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });

    if (!this.isPolicyRestored) {
      this._authService
        .GetPassPolicy(this.pwdValidatorParam.OrgId)
        .subscribe((res) => {
          if (res.IsSucceed) {
            this.pwdPolicyOrg = res.Result;
            this.isPolicyRestored = true;
            if (this.pwdPolicyOrg.PwdEnforce) {
              this.validationMessageForTT = this.generateTooltipContent(
                validatePasswordResult(this.pwdPolicyOrg),
                this.pwdPolicyOrg
              );
            }
          } else {
            this.isPolicyRestored = true;
          }
        });
    } else {
      if (this.pwdPolicyOrg.PwdEnforce) {
        this.validationMessageForTT = this.generateTooltipContent(
          validatePasswordResult(this.pwdPolicyOrg),
          this.pwdPolicyOrg
        );
      }
    }
  }

  generateTooltipContent(
    validationResults: any = {},
    policyOrg: Gblenv
  ): string {
    const messages = [
      {
        text: `• At least ${policyOrg.PwdLength} characters`,
        valid: validationResults.minLength || false,
        include: policyOrg.PwdLength > 0, // Include based on policyOrg property
      },
      {
        text: "• One uppercase letter",
        valid: validationResults.requireUppercase || false,
        include: policyOrg.PwdUpperCase || false,
      },
      {
        text: "• One lowercase letter",
        valid: validationResults.requireLowercase || false,
        include: policyOrg.PwdLowerCase || false,
      },
      {
        text: "• One numeric digit",
        valid: validationResults.requireNumber || false,
        include: policyOrg.PwdNumber || false,
      },
      {
        text: "• One special character",
        valid: validationResults.requireSpecialChar || false,
        include: policyOrg.PwdSpecChr || false,
      },
    ];

    this.isPasswordValid = !messages.some((message) => {
      return message.include && message.include !== message.valid;
    });

    return messages
      .filter((msg) => msg.include)
      .map(
        (msg) =>
          `<span class="${msg.valid ? "greenColor" : "redColor"}">${
            msg.text
          }</span>`
      )
      .join("<br/>");
  }
  // validatePassword(event) {
  //   this.pwdValidatorParam.Password = (event.target as HTMLInputElement).value;
  //   this.pwdValidatorParam.OrgId = 0;
  //   if (
  //     this.pwdValidatorParam.Password !== null &&
  //     this.pwdValidatorParam.Password.trim() !== ""
  //   ) {
  //     this._authService
  //       .ValidatePassword(this.pwdValidatorParam)
  //       .subscribe((res) => {
  //         if (res.IsSucceed) {
  //           this.pwdValidatorRes = res.Result;
  //           this.isPasswordValid = this.pwdValidatorRes.IsPasswordValid;
  //           this.validationMessage = this.pwdValidatorRes.ValidationMessage;
  //           this.validationMessageForTT = this.validationMessage.join("\n");
  //         }
  //       });
  //   }
  // }
  togglePasswordMask(): void {
    this.isPasswordMasked = !this.isPasswordMasked;
  }
}

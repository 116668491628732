<div class="layout-footer flex justify-content-between align-items-center">
    <div class="logo-text">
        <div style="cursor: pointer;" (click)="labportalLink()">
            <img src="assets/layout/images/logo/logo.png" alt="Vetportal" style="height: auto; width: 100px" />
        </div>
    </div>

    <div class="text ml-2">
        <span class="mr-3">Version: 1.9.0</span> <span>Rel. Date: Dec 26, 2024</span>
    </div>
</div>
<div class="layout-sidebar" [ngClass]="{ 'layout-sidebar-active': appMain.sidebarActive }"
  (click)="appMain.onSidebarClick($event)" (mouseover)="appMain.sidebarActive = true"
  (mouseleave)="appMain.sidebarActive = false">
  <div class="sidebar-logo">
    <a href="#" class="sidebar-pin" title="Toggle Menu" (click)="appMain.onToggleMenuClick($event)">
      <i class="pi pi-lock" *ngIf="appMain.staticMenuActive"></i>
      <i class="pi pi-lock-open" *ngIf="!appMain.staticMenuActive"></i>
    </a>
    <a routerLink="/dashboard" *ngIf="!appMain.staticMenuActive; else staticMenuActiveElseContent">
      <img *ngIf="!appMain.sidebarActive" alt="logo" src="assets/layout/images/logo/icon.png" height="30px"
        width="30px" />
      <img *ngIf="appMain.sidebarActive" alt="logo" src="assets/layout/images/logo/logo.png"
        style="height: auto; width: 100px" />
      <!-- <span class="app-name">VetPortal</span> -->
    </a>
    <ng-template #staticMenuActiveElseContent>
      <a routerLink="/dashboard">
        <img alt="logo" src="assets/layout/images/logo/logo.png" style="height: auto; width: 100px" />
      </a>
    </ng-template>
  </div>

  <div class="layout-menu-container" (click)="onMenuClick()">
    <ul class="layout-menu">
      <li app-menuitem *ngFor="let item of model; let i = index" [item]="item" [index]="i" [root]="true"
        [hidden]="menuHide(item)"></li>
    </ul>
  </div>
</div>